.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  margin: 0 2px;
  padding: 6px 20px;
  border: none;
  background-color: #fff;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover:not(:disabled) {
    background-color: #005478;
    color: #fff;
  }

  &:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }

  &.active {
    background-color: #005478;
    color: #fff;
    font-weight: 600;
  }
}

.pagination-container .pagination-button:first-child,
.pagination-container .pagination-button:last-child {
  background-color: transparent;
  color: #005478;

  &:hover:not(:disabled) {
    background-color: transparent;
    color: #005478;
    transform: scale(1.1);
  }

  &:disabled {
    color: #6f6d6d;
  }
}

.main-app-version {
  font-family: "Montserrat-Regular";
  line-height: 40px;
  padding: 2rem 3rem;

  h4 {
    margin: 0;
  }

  .appversion-table table {
    width: 100%;
    height: 100%;
    margin-top: 41px;
    border: 1px solid #fff;
    border-collapse: collapse;
  }

  .appversion-table table thead {
    background-color: black;
    color: white;
    border: 1px solid #d6d6d6;
    text-align: left;

    tr th {
      padding: 0.8rem;
      font-family: "Inter-Medium";
      font-size: 16px;
      font-weight: 500;
    }
  }

  .appversion-table table tbody tr:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .appversion-table table tbody tr:nth-child(even) {
    background-color: #d6d6d6;
  }
  .appversion-table table tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  .appversion-table table tbody td {
    padding: 1.3rem;
    // background-color: #fff;
    font-family: "Inter-Light";
    font-weight: 300;
    font-size: 14px;
    border-bottom: 1px solid #d6d6d6;
  }

  .no-appversion-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40vh;
  }
}

.edit-modal-container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 70px;
    height: 70px;
    background-color: #ccc;
    padding: 1rem;
    border-radius: 8px;
  }

  .edit-prod-name {
    color: #005478;
    text-decoration: underline;
    font-weight: 600;
  }
}

.status-container {
  margin-bottom: 1rem;

  .input-headings {
    font-family: "Inter-Medium";
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }

  .eye-status-icon {
    position: relative;
    top: -21px;
    left: 27rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

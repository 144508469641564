.main-dashboard {
  font-family: "Montserrat-Regular";
  line-height: 40px;
  padding: 2rem 3rem;

  .count-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

    .count-div {
      border-radius: 14px;
      width: 300px;
      padding: 1rem;
      padding-top: 1.5rem;

      display: flex;
      flex-direction: column;

      background-color: #fafafa;

      h3,
      h4 {
        margin: 0;
      }

      h4 {
        color: #332685;
      }

      .count-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 18px;
          font-family: "Montserrat-Medium";
        }
        .icon-container {
          display: flex;
          align-content: center;
          justify-content: center;
          background-color: #dddada;
          padding: 0.5rem;
          border-radius: 14px;
        }
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }

    .count-div-roadside {
      border-radius: 14px;
      width: 300px;
      padding: 1rem;
      padding-top: 1.5rem;

      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      background: linear-gradient(
        109.6deg,
        rgb(223, 234, 247) 11.2%,
        rgb(244, 248, 252) 91.1%
      );

      h3,
      h4 {
        margin: 0;
      }

      h4 {
        color: #332685;
      }

      .count-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 18px;
          font-family: "Montserrat-Medium";
        }
        .icon-container {
          display: flex;
          align-content: center;
          justify-content: center;
          background-color: #dddada;
          padding: 0.5rem;
          border-radius: 14px;
        }
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }

  .charts {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 3rem 0;
    margin-top: 4rem;
    height: 400px;
    .line-chart {
      width: 80%;

      background-color: #f4f4f4;
      border-radius: 13px;
      padding: 0.5rem;

      .select-box {
        position: relative;
        right: 0;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0.4rem;
      }
    }

    .doughnut-chart {
      width: 20%;
      background-color: #f4f4f4;
      border-radius: 13px;
      padding: 0.5rem;

      .select-box {
        position: relative;
        right: 0;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0.4rem;
      }
    }
  }
  .no-margin-p {
    margin: 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .heatmap-section {
    margin: 2rem 0;
    padding: 2rem;
    background-color: #fafafa;
    border-radius: 14px;
    h4 {
      font-size: 22px;
      font-family: "Montserrat-Medium";
      margin-bottom: 0.5rem;
      width: 100%;
      text-decoration: underline;
    }
    p {
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
  }
}

.count-div-roadside-value {
  border-radius: 14px;
  width: 300px;
  padding: 1rem;
  padding-top: 1.5rem;

  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  background: #f8ecf8;
  color: #000;

  h3,
  h4 {
    margin: 0;
  }

  h4 {
    color: #332685;
  }

  .count-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 18px;
      font-family: "Montserrat-Medium";
    }
    .icon-container {
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: #dddada;
      padding: 0.5rem;
      border-radius: 14px;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.column {
  flex: 1;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.service-card {
  background: linear-gradient(90deg, #e0efff 0%, #ffffff 100%);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.service-title {
  font-size: 18px;
  font-family: "Montserrat-Medium";
  margin-bottom: 5px;
  color: #333;
}

.service-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-family: "Montserrat-Regular";
  font-size: 14px;
  color: #333;
}

.status-text {
  font-weight: 700;
  font-family: "Montserrat-Medium";
  color: #333;
}

.count-text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat-Medium";
  color: #333;
}

.sidebar-main {
  padding-bottom: 40px;
  background: #f7f7f7;
  box-shadow: 1px 1px 4px #f7f7f7;
  height: 100vh;
  position: relative;
  color: #000 !important;

  .simplebar-scrollbar::before {
    background-color: transparent;
    opacity: 0.7 !important;
  }

  .sidebar-logo {
    width: 100%;
    background-color: #110942;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0.7rem;
    .crown-img {
      width: 50%;
      height: 50%;
      position: relative;
    }
    .logo-text {
      font-family: "Montserrat-Bold";
      color: #000;
      font-size: 20px;
    }
  }

  .sidebar-menu-list {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .each-icon-div {
      position: relative;

      .each-icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #000 !important;
        border-radius: 8px;
        padding: 12px 20px;
        margin: 0 2rem;
        font-size: 1.1rem;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-weight: 600;

        img {
          object-fit: contain;
          width: 24px;
          height: 24px;
        }
      }

      .each-icon:hover {
        background: #110942;
        transition: 0.5s ease;
        cursor: pointer;
        color: #fff !important;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        .svg-icon {
          filter: invert(100%);
        }
      }

      .each-icon.active {
        background: #110942;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        color: #fff !important;
        cursor: pointer;
        .svg-icon {
          filter: invert(100%);
        }
      }

      .submenu {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-left: 1.5rem;

        .each-icon {
          padding: 10px 15px;
          font-size: .9rem;
          &:hover {
            background: #110942;
            color: #fff !important;
          }
        }
      }

      .roadside-submenu {
        margin-left: 1.5rem !important;

        .each-icon {
          margin-bottom: 0.3rem;
        }
      }

      .dropdown-icon {
        margin-left: auto;
        transition: transform 0.3s;
      }

      .dropdown-icon.rotate {
        transform: rotate(180deg);
      }
    }
  }
}

@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
     padding: 1.5rem 2rem;
  border-bottom: 1px solid #bababa;

  .left-header {
    h4 {
      font-size: 1.4rem;
      padding: 0;
      font-family: "Inter-Medium";
      color: #000;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    gap: 3rem;

    .image {
      img {
        width: 35px;
        height: 35px;
      }
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .3rem;

      h4{
        font-family: "Inter-Regular";
        font-size: 17px;
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }
  }
}
